//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import pageMixin from '$mixins/page-mixin.js';
import LandingLayout from '$components/ui/landing-layout.vue';
import BottomSheetAyuda from './components/bottom-sheet-ayuda.vue';
import RightSheetAyuda from './components/right-sheet-ayuda.vue';
import {
  continueStep,
  fetchStepsData,
  downloadOffer,
  uploadInvoice,
  sendPhone,
  registerBack
} from './lib/api.js';

// Objeto que define el paso con su componente
const StepsComponentsRelation = {
  '1': {
    '1': 'StepSelectBuildingType',
    '2': 'StepAddCupsBill',
    '3': 'StepCustomPrice',
    '4': 'StepForm',
    '5': 'StepSelectSignatureType',
    '6': 'StepSignaturePending',
    '7': 'StepProcessingContract',
    '8': 'StepContractSuccessfull'
  },
  '2': {
    '1': 'StepSelectBuildingType',
    '2': 'StepAddCupsBill',
    '3': 'StepConsumptionData',
    '4': 'StepFeesSelection',
    '5': 'StepCustomPrice',
    '6': 'StepForm',
    '7': 'StepSelectSignatureType',
    '8': 'StepSignaturePending',
    '9': 'StepProcessingContract',
    '10': 'StepContractSuccessfull'
  }
};

const HelperSteps = {
  loading: 'StepLoading',
  readingIvoince: 'StepReadingInvoice'
};

const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms));

export default {
  name: 'CuotaPersonalizada',
  components: {
    LandingLayout,
    BottomSheetAyuda,
    RightSheetAyuda,
    StepLoading: () => import('./steps/step-loading.vue'),
    StepSelectBuildingType: () => import('./steps/step-select-building-type.vue'),
    StepSelectSignatureType: () => import('./steps/step-select-signature-type.vue'),
    StepAddCupsBill: () => import('./steps/step-add-cups-bill.vue'),
    StepReadingInvoice: () => import('./steps/step-reading-invoice.vue'),
    StepCustomPrice: () => import('./steps/step-custom-price.vue'),
    StepPriceSave: () => import('./steps/step-price-save.vue'),
    StepForm: () => import('./steps/AddContractInfo/step-form.vue'),
    StepSignaturePending: () => import('./steps/WaitingSignature/step-signature-pending.vue'),
    StepProcessingContract: () => import('./steps/step-processing-contract.vue'),
    StepContractSuccessfull: () => import('./steps/step-contract-successfull.vue'),
    StepConsumptionData: () => import('./steps/step-consumption-data.vue'),
    StepFeesSelection: () => import('./steps/step-fees-selection.vue'),
    StepOfferInWork: () => import('./steps/step-offer-in-work.vue')
  },
  mixins: [pageMixin],
  data: () => ({
    leadId: '',
    version: '1',
    currentStep: 'loading',
    currentSection: HelperSteps.loading,
    rates: [],
    selectedRates: [],
    channel: '',
    houseType: '',
    signatureType: '',
    contract: {},
    hiredPower: [],
    tarifaAtr: '',
    scoring: '',
    cups: {
      type: '',
      blob: '',
      data: ''
    },
    offers: {
      selected: {},
      external: {}
    },
    formExternalData: {},
    contractData: {},
    contractCreated: null
  }),
  computed: {
    canSeeBottomSheepAyuda() {
      return ['StepSelectBuildingType', 'StepAddCupsBill'].includes(this.currentSection);
    }
  },
  async created() {
    this.getLeadId();

    try {
      const response = await fetchStepsData(this.leadId);
      this.processStepData(response.data);
    } catch (error) {
      this.$notify.error('Ha ocurrido un error, contacta con el administrador.');
    }
  },
  methods: {
    async handleSendPhone({ phone }) {
      try {
        await sendPhone(this.leadId, phone);
        this.$notify.success('¡Telefono recibido! Le contactaremos lo antes posible.');
      } catch (error) {
        this.$notify.error('Ha habido un error, vuelva a intentarlo mas tarde.');
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    async handleGoBack() {
      const { currentStep, version } = this;
      let prevStep = String(Number(currentStep) - 1);

      if (version === '2' && prevStep === '3') {
        prevStep = String(Number(prevStep) - 1);
      }

      this.currentStep = prevStep;
      this.currentSection = StepsComponentsRelation[version][prevStep];
      registerBack(this.leadId, prevStep);
    },
    async handleDownloadOffer({ offerId, stopLoading }) {
      try {
        const response = await downloadOffer(this.leadId, offerId);
        stopLoading();
        const { url } = response.data;

        window.open(url, '_blank');
      } catch (error) {
        stopLoading();
        this.$notify.error('Error intentando descargar la oferta, vuelva a intentarlo.');
      }
    },
    getLeadId() {
      this.leadId = this.$route.params.uid;
    },
    isAutomaticChannel() {
      return this.channel === 'automatic';
    },
    setCurrentStepComponent() {
      const { currentStep, version } = this;

      if (this.checkPermissionsToSeeComponent(version, currentStep)) {
        this.currentSection = StepsComponentsRelation[String(version)][String(currentStep)];
        return;
      }

      this.currentSection = 'StepOfferInWork';
    },
    checkPermissionsToSeeComponent(version, currentStep) {
      if (version === '1') {
        return true;
      }

      if (currentStep !== '3' && currentStep !== '4') {
        return true;
      }

      if (window.localStorage.getItem('channel') === null) {
        return false;
      }

      return true;
    },
    processStepData(data) {
      const { version, currentStep, steps, channel } = data;
      this.version = String(version);
      this.currentStep = String(currentStep);
      this.setCurrentStepComponent(String(version), String(currentStep));
      this.channel = channel;

      const stateSetters = {
        // Version
        '1': {
          '1': this.setHouseType,
          '2': this.setCupsDataAndOffers,
          '3': this.setOffersData,
          '4': this.setContractData,
          '5': this.setSignatureType
        },
        '2': {
          '1': this.setHouseType,
          '2': this.setCups,
          '3': this.setRates,
          '4': this.setStep4V2,
          '5': this.setOffersData,
          '6': this.setContractData,
          '7': this.setSignatureType
        }
      };

      // Lanza los metodos descritos en el objeto stateSetters (si el back-end los devuelve)
      Object.keys(steps).forEach(stepIndex => {
        const method = stateSetters[version][stepIndex];
        const step = steps[stepIndex];

        if (!method) {
          // eslint-disable-next-line no-console
          console.warn('[offers] Unknown step %s for version %s', stepIndex, version);
          return;
        }
        method(step);
      });

      if (this.isAutomaticChannel()) {
        if (steps[4].externalData !== undefined) {
          this.scoring = steps[4].externalData.scoring.status;
        }
      }

      if (!this.isAutomaticChannel()) {
        if (steps[6].externalData !== undefined) {
          this.scoring = steps[6].externalData.scoring.status;
        }
      }
    },
    setHouseType(step) {
      if (!step.finished) return;
      this.houseType = step.inputData.house_type;
    },
    setCupsDataAndOffers(step) {
      if (!step.finished) return;
      this.cups = step.inputData;
      this.offers.external = step.externalData;

      this.formExternalData = {
        fecha: step.externalData.fecha,
        caducidad: step.externalData.caducidad,
        datosEstudio: step.externalData.datosEstudio
      };
    },
    setCups(step) {
      if (!step.finished) return;
      this.cups = step.inputData;
      this.hiredPower = Object.keys(step.externalData.potenciaContratada).map(
        key => step.externalData.potenciaContratada[key]
      );
      this.tarifaAtr = step.externalData.tarifaAtr;
    },
    setRates(step) {
      if (!step.finished) return;
      this.rates = step.externalData.tarifas;
    },
    setOffersData(step) {
      if (!step.finished) return;
      this.formExternalData.offerSelected = step.inputData.offerSelected;
      this.offers.selected = step.inputData;
    },
    setSignatureType(step) {
      if (!step.finished) return;
      this.signatureType = step.inputData.channelId;
    },
    setStep4V2(step) {
      if (!step.finished) return;
      this.offers.external = step.externalData;

      this.formExternalData = {
        fecha: step.externalData.fecha,
        caducidad: step.externalData.caducidad,
        datosEstudio: step.externalData.datosEstudio
      };

      this.selectedRates = step.inputData.selectedRates;
    },
    changeStep(step) {
      this.currentSection = StepsComponentsRelation[step];
    },
    setContractData(step) {
      if (typeof step.inputData === 'object' && !Array.isArray(step.inputData)) {
        this.contractData = step.inputData;

        if (step.externalData) {
          this.contractCreated = step.externalData.contractCreated;
        }

        return;
      }

      this.contractData = {};
    },
    getNextStep(step) {
      const steps = Object.keys(StepsComponentsRelation);
      return steps[steps.indexOf(step) + 1];
    },
    async handleStepContinue(data) {
      try {
        let response = null;

        if (data.type === 'invoice-upload') {
          const formData = new FormData();

          formData.append('lead_id', this.leadId);
          formData.append('action', data.action);
          formData.append('file', data.data);

          // Muestra la pantalla de carga
          this.currentSection = HelperSteps.readingIvoince;

          try {
            response = await uploadInvoice(this.leadId, formData);
          } catch (error) {
            this.$notify.info('No ha sido posible obtener los datos de tu factura, añade tu CUPS');
            this.currentSection = 'StepAddCupsBill';
            return;
          }

          // Finaliza la pantalla de carga
          this.$refs.stepComponent.finishLoop();
          await wait(200);
        } else {
          response = await continueStep(this.leadId, this.currentStep, data);
        }

        this.processStepData(response.data);
      } catch (error) {
        this.$notify.error('Ha ocurrido un error, contacta con el administrador.');

        if (this.$refs.stepComponent.turnOffLoading) {
          this.$refs.stepComponent.turnOffLoading();
        }

        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }
};
